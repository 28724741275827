/**
 * integrator.config
 * API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Credentials
 */
export interface Credentials { 
    grant_type?: Credentials.GrantTypeEnum;
    client_id?: string;
    client_secret?: string;
    scope?: string;
    username?: string;
    password?: string;
    refresh_token?: string;
}
export namespace Credentials {
    export type GrantTypeEnum = 'password' | 'refresh_token';
    export const GrantTypeEnum = {
        Password: 'password' as GrantTypeEnum,
        RefreshToken: 'refresh_token' as GrantTypeEnum
    };
}


