import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { UsersService } from '@src/api/v1';
import { UserUI } from '@src/app/types';

import { ROLE_PERMISSIONS } from '../constants';
import { JwtTokenPayload, RoleCode } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  private userData?: UserUI;

  private readonly usersService = inject(UsersService);
  private readonly permissionsService = inject(NgxPermissionsService);
  private readonly rolesService = inject(NgxRolesService);

  get user() {
    return this.userData;
  }

  async init(payload: JwtTokenPayload): Promise<UserUI> {
    const user: UserUI = await lastValueFrom(this.usersService.getCurrentUser());
    user.fullName = [user?.firstName, user?.lastName].join(' ');
    user.roles = payload.roles;
    if (user.roles) {
      user.permissions = this.getPermissions(user.roles);
      user.rolesWithPermissions = this.getRolesWithPermissions(user);

      this.permissionsService.loadPermissions(user.permissions);
      this.rolesService.addRoles(user.rolesWithPermissions as Record<string, string[]>);
    }

    this.userData = user;

    return user;
  }

  private getPermissions(roles: RoleCode[]) {
    const permissions = roles.reduce<string[]>((acc, role) => {
      if (ROLE_PERMISSIONS[role]) {
        acc.push(...ROLE_PERMISSIONS[role]);
      }

      return acc;
    }, []);

    return Array.from(new Set(permissions));
  }

  private getRolesWithPermissions(user: UserUI) {
    return user.roles?.reduce<Partial<Record<RoleCode, string[]>>>((acc, role) => {
      if (ROLE_PERMISSIONS[role]) {
        acc[role] = [...ROLE_PERMISSIONS[role]];
      }

      return acc;
    }, {});
  }
}
