import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '@src/app/services';

import { AuthService, LogoutService } from '../services';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private alertService: AlertService;
  private logoutService: LogoutService;
  private authService: AuthService;

  constructor() {
    this.alertService = inject(AlertService);
    this.logoutService = inject(LogoutService);
    this.authService = inject(AuthService);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(this.handleError(request)));
  }

  handleError =
    (request: HttpRequest<any>) =>
    (error: any): Observable<HttpEvent<any>> => {
      if (error.status === 401) {
        const refreshToken = this.authService.getRefreshToken();
        if (refreshToken) {
          this.authService.changeToken(refreshToken).subscribe({
            error: () => {
              this.logoutService.logout();
            },
          });
        } else {
          this.logoutService.logout();
        }
      } else {
        console.log('++ error', error);

        let errorMessage = [];
        if (error?.error instanceof ErrorEvent) {
          // client-side error
          if (error?.error?.error) {
            errorMessage.push(`${error?.error?.error?.status}`);
            if (!!error?.url) {
              errorMessage.push(`Ресурс: ${error?.url}, [${error?.error?.error?.code}]`);
            }
            errorMessage.push(`Ошибка: ${error?.error?.error?.message}`);
          }
        } else if (error?.error?.message) {
          errorMessage.push(error.error.message);
        } else {
          // server-side error
          errorMessage.push(`Server-code: ${error?.error?.code}`);
          errorMessage.push(`Server-message: ${error?.error?.message}`);
          errorMessage.push(`${error?.name}`);
          if (!!error?.url) {
            errorMessage.push(`Ресурс: ${error?.url}`);
          }
          errorMessage.push(`Ошибка: ${error?.statusText} [${error?.status}] Сообщение: ${error?.message}`);
        }

        this.alertService.error(errorMessage.join(' | '), true);
      }
      return throwError(() => new Error(error));
    };
}
