import { Injectable } from '@angular/core';
import models from '@src/i18n/models/ru.json';

const i18n: { models: { [key: string]: { [key: string]: string } } } = { models };

@Injectable({
  providedIn: 'root',
})
export class CaptionService {
  getCaption(fieldName: string, tableName?: string) {
    if (!tableName) {
      console.warn(`tableName для поля '${fieldName}' не указан.`);
      return fieldName;
    }

    const i18nModel = i18n.models[tableName];
    if (i18nModel) {
      const i18nProperty = i18nModel[fieldName];
      if (i18nProperty) {
        return i18nProperty;
      }
    }

    console.warn(`Поле '${fieldName}' для таблицы '${tableName}' не найдено в файле i18n.`);
    return fieldName;
  }
}
