import { inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly messageService = inject(MessageService);

  success(message: string, sticky: boolean = false) {
    this.messageService.clear();
    this.messageService.add({
      key: 'global-toast',
      severity: 'success',
      summary: 'Успешно',
      detail: message,
      sticky,
    });
  }

  info(message: string, sticky: boolean = false) {
    this.messageService.clear();
    this.messageService.add({
      key: 'global-toast',
      severity: 'info',
      summary: 'Информация',
      detail: message,
      sticky,
    });
  }

  warn(message: string, sticky: boolean = false) {
    this.messageService.clear();
    this.messageService.add({
      key: 'global-toast',
      severity: 'warn',
      summary: 'Внимание',
      detail: message,
      sticky,
    });
  }

  error(message: string, sticky: boolean = false) {
    this.messageService.clear();
    this.messageService.add({
      key: 'global-toast',
      severity: 'error',
      summary: 'Ошибка',
      detail: message,
      sticky,
    });
  }
}
