import { UserDto } from '@src/api/v1';

export enum RoleCode {
  /** Администратор */
  ADMIN = 'ADMIN',

  /** Пользователь */
  USER = 'USER',
}

export interface JwtTokenPayload {
  uuid: string;
  firstName: string;
  sub: string;
  exp: number;
  roles: RoleCode[];
}

export type CallbackAfterCreateSession = (user?: UserDto) => Promise<unknown> | unknown;
