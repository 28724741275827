import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyInSpace',
  standalone: true,
})
export class EmptyInSpacePipe implements PipeTransform {
  transform(value?: any): string {
    return value || ' ';
  }
}
