/**
 * integrator.config
 * API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserLogFilter { 
    /**
     * Уровень логирования события
     */
    logLevel?: UserLogFilter.LogLevelEnum;
    /**
     * номер события (записи) со сквозной нумерацией
     */
    eventId?: number;
    /**
     * дата события
     */
    eventDate?: string;
    /**
     * Уникальный идентификатор записи из esb-очереди c которым связано логируемое событие
     */
    replicationId?: number;
    /**
     * название модуля–источника (от какого модуля КШД исходит сообщение)
     */
    nameModule?: string;
    /**
     * наименование системы–источника
     */
    systemSource?: string;
    /**
     * наименование системы–получателя
     */
    target?: string;
    /**
     * статус события (ошибочная ситуация, успешная операция)
     */
    status?: string;
    /**
     * вид операции (создание/изменение нового объекта, запись/чтение соответствия идентификаторов объектов и т.д.)
     */
    nameEvent?: string;
    /**
     * тип объекта
     */
    nameObject?: string;
    /**
     * идентификатор объекта системы–источника
     */
    sourceId?: string;
    /**
     * идентификатор объекта системы–потребителя
     */
    asId?: string;
    /**
     * код ошибочной ситуации
     */
    errorCode?: string;
    /**
     * расшифровка кода ошибочной ситуации
     */
    errorDescription?: string;
    /**
     * передаваемое сообщение (данные принятые от системы–источника и систем–получателей)
     */
    originalMessage?: string;
    dateFrom?: string;
    dateTo?: string;
}
export namespace UserLogFilter {
    export type LogLevelEnum = 'all' | 'debug' | 'info' | 'warn' | 'error';
    export const LogLevelEnum = {
        All: 'all' as LogLevelEnum,
        Debug: 'debug' as LogLevelEnum,
        Info: 'info' as LogLevelEnum,
        Warn: 'warn' as LogLevelEnum,
        Error: 'error' as LogLevelEnum
    };
}


