import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { SessionService } from '../services';

export const AuthGuard: CanActivateFn = (route, state) => {
  if (!inject(SessionService).isLogged()) {
    if (state.url.indexOf('returnUrl') === -1) {
      return inject(Router).navigate(['/login'], {
        queryParams: { returnUrl: state.url !== '/' ? state.url : undefined },
      });
    }
    return false;
  }

  return true;
};
