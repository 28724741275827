import { inject, Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  private readonly authService = inject(AuthService);

  constructor() {
    this.reload = this.reload.bind(this);
  }

  /**
   * Метод где выходим из сессии пользователя
   */
  logout() {
    this.authService.clearTokens();

    this.reload();
  }

  private reload() {
    window.location.reload();
  }
}
