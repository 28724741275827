import { RoleCode } from '../types';

export const ROLE_PERMISSIONS: Record<RoleCode, readonly string[]> = {
  ADMIN: [
    'configurationView',

    'adaptersView',
    'adaptersCreate',
    'adaptersEdit',
    'adaptersCopy',
    'adaptersDelete',

    'routesView',
    'routesCreate',
    'routesEdit',
    'routesCopy',
    'routesDelete',
    'routesRestart',
    'routesStop',
    'routesTurnOn',
    'routesTurnOff',

    'selectionCriteriaView',
    'selectionCriteriaCreate',
    'selectionCriteriaEdit',
    'selectionCriteriaCopy',
    'selectionCriteriaDelete',

    'replicationDataView',
    'replicationDataEdit',

    'dictionariesView',
    'dictionariesCreate',
    'dictionariesEdit',
    'dictionariesDelete',

    'relationsView',
    'relationsCreate',
    'relationsEdit',
    'relationsDelete',

    'userLogView',
    'userLogXMLView',

    'systemLogView',

    'usersView',
    'usersCreate',
    'usersEdit',
    'usersDelete',

    'modulesSettingsPageViewing',
    'modulesSettingsCreate',
    'modulesSettingsCreate',
    'modulesSettingsEdit',
    'modulesSettingsCopy',
    'modulesSettingsDelete',

    'settingsView',
    'settingsMQView',
    // Временно скрыли
    // 'settingsFileView',
    // 'settingsCertificatesView',

    'settingsNotificationRulesView',
    'settingsNotificationRulesCreate',
    'settingsNotificationRulesEdit',
    'settingsNotificationRulesDelete',

    'aboutView',
  ],
  USER: ['userLogView', 'aboutView'],
} as const;
