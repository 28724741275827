import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_FORMAT = 'dd.MM.yyyy HH:mm:ss';

@Pipe({
  name: 'dateDisplayValue',
  standalone: true,
})
export class DateDisplayValuePipe implements PipeTransform {
  transform(value?: string | number | Date | null, format?: string): string {
    return value ? formatDate(value, format ?? DEFAULT_FORMAT, 'ru') : '';
  }
}
