import { inject, Pipe, PipeTransform } from '@angular/core';

import { CaptionService } from '../services';
import { ModuleSettingType } from '../types';

@Pipe({
  name: 'caption',
  standalone: true,
})
export class CaptionPipe implements PipeTransform {
  private captionService = inject(CaptionService);

  transform(fieldName: string, tableName?: string, customFieldsNames?: ModuleSettingType['naming']): string {
    return customFieldsNames?.[fieldName] ?? this.captionService.getCaption(fieldName, tableName);
  }
}
