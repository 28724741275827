import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AccessTokenResponse, AuthService as ApiAuthService } from '@src/api/auth';
import { StorageService, StorageKeys } from '@src/app/modules/storage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token?: string;
  private refreshToken?: string;

  private readonly apiAuthService = inject(ApiAuthService);
  private readonly storageService = inject(StorageService);

  setTokens({ access_token, refresh_token }: AccessTokenResponse): void {
    if (access_token) {
      this.setToken(access_token);
    }

    if (refresh_token) {
      this.setRefreshToken(refresh_token);
    }
  }

  setToken(token: string): void {
    this.token = token;
    this.writeTokenToStorage(token);
  }

  setRefreshToken(refreshToken: string): void {
    this.refreshToken = refreshToken;
    this.writeRefreshTokenToStorage(refreshToken);
  }

  getToken(): string | null {
    return this.token || this.readTokenFromStorage();
  }

  getRefreshToken(): string | null {
    return this.refreshToken || this.readRefreshTokenFromStorage();
  }

  changeToken(refreshToken: string) {
    return this.apiAuthService
      .getToken({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      })
      .pipe(
        map(response => {
          this.setTokens(response);

          return response;
        }),
      );
  }

  isLogged(): boolean {
    return !!this.getToken();
  }

  clearTokens(): void {
    this.removeTokenFromStorage();
    this.removeRefreshTokenFromStorage();

    this.token = undefined;
    this.refreshToken = undefined;
  }

  readTokenFromStorage(): string | null {
    return this.storageService.getItem(StorageKeys.Token);
  }

  writeTokenToStorage(token: string): void {
    this.storageService.setItem(StorageKeys.Token, token);
  }

  removeTokenFromStorage(): void {
    this.storageService.removeItem(StorageKeys.Token);
  }

  readRefreshTokenFromStorage(): string | null {
    return this.storageService.getItem(StorageKeys.RefreshToken);
  }

  writeRefreshTokenToStorage(refreshToken: string): void {
    this.storageService.setItem(StorageKeys.RefreshToken, refreshToken);
  }

  removeRefreshTokenFromStorage(): void {
    this.storageService.removeItem(StorageKeys.RefreshToken);
  }
}
