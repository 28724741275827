import { Pipe, PipeTransform } from '@angular/core';
import { normalizeDictionaryValue } from '@src/app/utils';
import { Dictionary } from '@src/app/constants';

@Pipe({
  name: 'dictionaryDisplayValue',
  standalone: true,
})
export class DictionaryDisplayValuePipe implements PipeTransform {
  transform(id: number | string, dictionary: Dictionary[]): string {
    return normalizeDictionaryValue(id, dictionary);
  }
}
