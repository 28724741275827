import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessTokenResponse } from '@src/api/v1';
import { JwtHelperService } from '@auth0/angular-jwt';

import { CallbackAfterCreateSession, JwtTokenPayload } from '../types';
import { AuthService } from './auth.service';
import { AuthUserService } from './auth-user.service';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private resolve?: (value: unknown) => void;

  private callbacksAfterCreateSession: CallbackAfterCreateSession[] = [];

  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly authService = inject(AuthService);
  private readonly logoutService = inject(LogoutService);
  private readonly authUserService = inject(AuthUserService);

  constructor() {
    this.restoreSession().then(this.resolve);
  }

  addCallbackAfterCreateSession(callback: CallbackAfterCreateSession) {
    this.callbacksAfterCreateSession.push(callback);
  }

  get user() {
    return this.authUserService.user;
  }

  getToken(): string | null {
    return this.authService.getToken();
  }

  isLogged(): boolean {
    return this.authService.isLogged();
  }

  logout() {
    this.logoutService.logout();
  }

  init() {
    return new Promise(resolve => {
      this.restoreSession().then(resolve);
    });
  }

  /**
   * Восстанавливаем сессию
   */
  async restoreSession() {
    const access_token = this.authService.readTokenFromStorage();
    if (access_token) {
      await this.createSession({ access_token });
    }
  }

  /**
   * Создаем сессию
   * @param sessionData токены
   * @param redirect флаг, что нужно произвести редирект на returnUrl
   */
  async createSession({ access_token, refresh_token }: AccessTokenResponse, redirect?: boolean) {
    if (!access_token) {
      throw new Error('Нет данных по токену сессии');
    }

    this.authService.setTokens({ access_token, refresh_token });

    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken<JwtTokenPayload>(access_token);

    if (!decodedToken) {
      throw new Error('Ошибка декодирования токена');
    }

    const user = await this.authUserService.init(decodedToken);

    await Promise.all(this.callbacksAfterCreateSession.map(callback => callback(user)));

    const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    if (redirect) {
      await this.router.navigateByUrl(returnUrl ?? '');
    }
  }
}
